<template>
  <div id="step-1-content">
    <div class="dark-blue-text label-font">
      お部屋のスタイルを選んでください
    </div>
    <div class="mt-5">
      <div class="row" v-if="!furnishingStyles.length > 0">
        <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
          <b-skeleton-img></b-skeleton-img>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
          <b-skeleton-img></b-skeleton-img>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
          <b-skeleton-img></b-skeleton-img>
        </div>
      </div>
      <hooper
        :settings="hooperSettings"
        class="h-auto slider"
        ref="roomStyleSlider"
        :centerMode="true"
        :infiniteScroll="true"
        :mouseDrag="false"
        v-else
      >
        <slide
          class="slider-item pb-2"
          v-for="(el, i) in furnishingStyles"
          :key="i"
        >
          <div class="row">
            <div
              :class="
                _i === 0
                  ? 'col-12 col-md-12 col-lg-12 col-xl-12'
                  : 'col-12 col-md-6 col-lg-6 col-xl-12'
              "
              v-for="(_el, _i) in el"
              :key="_i"
            >
              <div
                :class="
                  selectedFurnishingStyle === _el.id
                    ? 'style-type style-type-active'
                    : 'style-type'
                "
                v-on:click="selectedFurnishingStyle = _el.id"
              >
                <img
                  :src="
                    _el.image_url
                      ? _el.image_url
                      : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQQSFf9rmXu2Rrmf66Exb9JjGsxzO8zyr5cQ&usqp=CAU'
                  "
                  onerror="this.src='/img/image-error.png'"
                />
                <div class="style-label-font">
                  {{ _el.name }}
                </div>
              </div>
            </div>
          </div>
        </slide>
        <navigation slot="hooper-addons">
          <template v-slot:hooper-prev>
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
            />
          </template>
          <template v-slot:hooper-next>
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
            />
          </template>
        </navigation>
      </hooper>
    </div>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import {
  cus_stagingService_GetFunishingStyle,
  cus_stagingService_UpdateSRStyleID,
} from '../../services/customer/staging';

// Import Lodash
import { chunk } from 'lodash';

// Import Hopper JS
import { Hooper, Slide, Navigation } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | New Request',
    };
  },

  components: {
    Hooper,
    Slide,
    Navigation,
  },

  computed: {
    ...mapGetters(['staging']),
  },

  data() {
    return {
      furnishingStyles: [],
      selectedFurnishingStyle: 1,
      hooperSettings: {
        centerMode: true,
        infiniteScroll: true,
        breakpoints: {
          2400: {
            itemsToShow: 5.6,
          },
          1800: {
            itemsToShow: 5.6,
          },
          1500: {
            itemsToShow: 3.6,
          },
          1200: {
            itemsToShow: 3.6,
          },
          1000: {
            itemsToShow: 3.6,
          },
          0: {
            itemsToShow: 1.6,
          },
        },
      },
    };
  },

  watch: {
    staging() {
      this.retrieveFurnishingStyles();
    },
  },

  created() {
    this.$parent.steps.proceedDisabled = true;
    console.log('created');
    console.log(this.staging);

    if (this.staging.accessToken !== null) {
      this.retrieveFurnishingStyles();
    }
  },

  methods: {
    setStepProceed() {
      this.$parent.steps.proceedDisabled = false;
    },

    retrieveFurnishingStyles() {
      let _this = this;

      cus_stagingService_GetFunishingStyle({
        token: _this.staging.accessToken,
      })
        .then((response) => {
          let data = response.data;
          console.log(data);
          _this.furnishingStyles = chunk(data, 1);

          _this.selectedFurnishingStyle =
            _this.staging.info.furnishing_style_id;

          _this.setStepProceed();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    roomStyleSliderAction(action) {
      if (action === 'next') {
        this.$refs.roomStyleSlider.slideNext();
      } else {
        this.$refs.roomStyleSlider.slidePrev();
      }
    },

    updateFurnishingStyle() {
      let _this = this;
      _this.$parent.scrollTop();
      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      return cus_stagingService_UpdateSRStyleID({
        id: _this.staging.info.id,
        furnishing_style_id: _this.selectedFurnishingStyle,
        request_category: 1,
        token: _this.staging.accessToken,
      })
        .then((response) => {
          let data = response.data.result;

          _this.selectedFurnishingStyle = data.furnishing_style_id;
          _this.$store.state.staging.info.furnishing_style_id =
            data.furnishing_style_id;

          _this.$parent.steps.completed.push(1);
          _this.$parent.steps.current = 2;
          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$parent.scrollTop();
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$bvToast.toast('エラーが起こりました。再度お試しください。', {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            });
          }, 500);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#step-1-content .slider .slider-item .style-type {
  cursor: pointer;
  text-align: center;
  position: relative;
  border: 4px solid #fff;
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #f5f5f5;
  margin-right: 10px;
  margin-left: 10px;
}

#step-1-content .slider .slider-item .style-type:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#step-1-content .slider .slider-item .style-type-active {
  border: 4px solid #055ab8;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#step-1-content .slider .slider-item .style-type img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.step-actions {
  margin-top: 200px;
  margin-bottom: 50px;
  width: 500px;
  float: right;
}

@media only screen and (max-width: 1199px) {
  .step-actions {
    margin-top: 20px;
    width: 100%;
    float: none;
  }
}

.label-font {
  font-weight: bold;
  margin-left: 50px;
}

@media only screen and (max-width: 991px) {
  .label-font {
    margin-left: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .label-font {
    margin-left: 26px;
  }
}

.style-label-font {
  font-weight: bold;
  font-size: 12px;
  margin: 10px 0;
}
</style>
